.p-footer {
  position: relative;
  background: #000;
  overflow: hidden;
  @media #{$breakpoint} {
    margin-top: -1px;
    padding-top: 6rem;
  }
  &_top {
    padding: 11.7rem 0 4rem;
    @media #{$breakpoint} {
      padding: 0;
    }
    &_wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 2rem;
      border-bottom: 1px solid #595959;
      @media #{$breakpoint} {
        display: block;
        padding-bottom: 4rem;
      }
    }
  }
  &_logo {
    display: block;
    width: 11rem;
    height: 10rem;
    @media #{$breakpoint} {
      margin: 0 auto 4rem;
    }
  }
  &_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media #{$breakpoint} {
      display: block;
    }
    &_list {
      position: relative;
      @media #{$breakpoint} {
        margin-bottom: 3.2rem;
        text-align: center;
      }
      &_wrapper {
        display: flex;
        justify-content: space-between;
        @media #{$breakpoint} {
          width: 27.6rem;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0 auto;
        }
      }
      &_link {
        display: block;
        height: 12rem;
        padding: 0 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 12rem;
        color: #fff;
        transition: color $kitekiTransition;
        @media #{$breakpoint} {
          height: auto;
          padding: 0 2rem;
          line-height: normal;
        }
        &:hover {
          color: $keyColor;
          @media #{$breakpoint} {
            color: #fff;
          }
        }
      }
      &_sub {
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        &_wrapper {
          position: absolute;
          width: 15rem;
          left: -2.5rem;
          bottom: 10.5rem;
          padding: 2rem;
          background: #fff;
          line-height: (54 / 32);
          opacity: 0;
          visibility: hidden;
          transition: opacity $kitekiTransition, visibility $kitekiTransition;
          @media #{$breakpoint} {
            bottom: 4rem;
            padding: 2rem;
            line-height: 2;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -.5rem;
            width: 0;
            height: 0;
            margin: 0 auto;
            border-style: solid;
            border-width: .5rem .5rem 0 .5rem;
            border-color: #fff transparent transparent transparent;
          }
          &.is-active {
            opacity: 1;
            visibility: visible;
          }
        }
        &_link {
          display: block;
          width: 100%;
          transition: background $kitekiTransition, color $kitekiTransition;
          &:hover {
            background: $keyColor;
            color: #fff;
          }
        }
      }
    }
    &_sns {
      &_list {
        &_wrapper {
          display: flex;
          align-items: center;
          margin-left: 2rem;
          @media #{$breakpoint} {
            justify-content: center;
            margin-left: 0;
          }
        }
        &_link {
          display: block;
          svg {
            g {
              transition: fill $kitekiTransition;
            }
          }
          &:hover {
            svg {
              g {
                fill: $keyColor;
                @media #{$breakpoint} {
                  color: #fff;
                }
              }
            }
          }
          &.is-insta {
            width: 2rem;
            height: 2rem;
          }
          &.is-fb {
            width: 1.1rem;
            height: 2rem;
            margin-left: 2rem;
          }
        }
      }
    }
  }
  &_bottom {
    &_wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      padding: 8rem 0 12rem;
      @media #{$breakpoint} {
        display: block;
        padding: 4rem 0 2rem;
      }
    }
    &_txt {
      position: absolute;
      bottom: -5rem;
      left: -.5rem;
      font-family: $fontDM;
      font-size: 32.5rem;
      color: $footerFont;
      z-index: 0;
      @media #{$breakpoint} {
        bottom: -1.8rem;
        left: 1.8rem;
        font-size: 11rem;
      }
    }
    &_copyright {
      position: relative;
      font-size: 1.1rem;
      color: #fff;
      letter-spacing: 0.1em;
      z-index: 1;
      @media #{$breakpoint} {
        font-size: 1rem;
        text-align: center;
      }
    }
    &_logo {
      position: relative;
      display: block;
      width: 17rem;
      height: 1.7rem;
      z-index: 1;
       @media #{$breakpoint} {
         margin: 0 auto 8.2rem;
       }
    }
  }
}
