.p-wrapper {
  width: (2480 / 2720 * 100 + 0%);
  max-width: 124rem;
  margin: 0 auto;
  @media #{$breakpoint} {
    width: 100%;
    padding: 0 2rem;
  }
}

.p-bread_scrap {
  font-size: 1.2rem;
  color: #969696;
  &_wrapper {
    padding-top: 2rem;
  }
  &_container {
    display: flex;
  }
  &_link {
    position: relative;
    margin-right: 1.7rem;
    color: #000;
    transition: color $kitekiTransition;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -1.2rem;
      width: 0.6rem;
      height: 0.1rem;
      background: #969696;
    }
    &:hover {
      color: $keyColor;
    }
  }
}

.p-news {
  &_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12rem 0;
    @media #{$breakpoint} {
      display: block;
      padding: 10rem 0 0;
    }
  }
  &_content {
    width: 62rem;
    padding-top: 4.5rem;
    @media #{$breakpoint} {
      width: 100%;
      padding: 0 2rem;
      margin-bottom: 10rem;
    }
  }
  &_content {
    &_ttl {
      margin-bottom: 6.5rem;
      @media #{$breakpoint} {
        margin-bottom: 4rem;
      }
    }
  }
  &_img {
    &_wrapper {
      width: calc(100% - 62rem);
      @media #{$breakpoint} {
        width: 100%;
      }
    }
  }
}
