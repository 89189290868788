.p-header {
  position: fixed;
  top: 2rem;
  left: 0;
  right: 0;
  width: calc(100% - 4rem);
  margin: 0 auto;
  background: #000;
  z-index: 9999;
  transition: height $kitekiTransition;
  @media #{$headerBreakpoint} {
    top: 0;
    width: 100%;
    height: 8rem;
    overflow-y: hidden;
    padding: 0 0 1.5rem;
    background: none;
  }
  &.is-active {
    @media #{$headerBreakpoint} {
      height: 100vh;
      overflow-y: auto;
    }
  }
  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    margin: 0 auto;
    @media #{$headerBreakpoint} {
      width: 100%;
      flex-wrap: wrap;
      padding: 0;
    }
  }
  &_head {
   @media #{$headerBreakpoint} {
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 1.5rem 2rem;
     background: #000;
   } 
  }
  &_logo {
    display: block;
    width: 8.5rem;
    height: 7.8rem;
    transition: opacity $kitekiTransition;
    @media #{$headerBreakpoint} {
      width: 5.5rem;
      height: 5rem;
    }
    &:hover {
      opacity: .6;
    }
  }
  &_btn {
    display: none;
    @media #{$headerBreakpoint} {
      position: relative;
      display: block;
      width: 4rem;
      height: 4rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: 0.1rem;
      margin: 0 auto;
      background: $keyColor;
      transform: translateY(-.5rem);
      transition: transform $kitekiTransition;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: 0.1rem;
      margin: 0 auto;
      background: $keyColor;
      transform: translateY(.5rem);
      transition: transform $kitekiTransition;
    }
    &.is-active {
      &::before {
        transform: rotate(32deg);
      }
      &::after {
        transform: rotate(-32deg);
      }
    }
  }
  &_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity $kitekiTransition, visibility $kitekiTransition;
    @media #{$headerBreakpoint} {
      width: 100%;
      display: block;
      padding: 0 2rem 12.9rem;
      background: rgba(#000, .9);
      opacity: 0;
      visibility: hidden;
    }
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
    &_list {
      @media #{$headerBreakpoint} {
        border-bottom: 1px solid #595959;
      }
      &_wrapper {
        display: flex;
        justify-content: space-between;
        @media #{$headerBreakpoint} {
          display: block;
          padding-top: 4rem;
          margin-bottom: 3rem;
        }
      }
      &_link {
        display: block;
        width: 100%;
        height: 12rem;
        padding: 0 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 12rem;
        color: #fff;
        text-align: left;
        transition: color $kitekiTransition;
        @media #{$headerBreakpoint} {
          height: auto;
          padding: 2rem 0;
          line-height: normal;
          font-size: 3.2rem;
        }
        &:hover {
          color: $keyColor;
          @media #{$headerBreakpoint} {
            color: #fff;
          }
        }
        &.is-btn {
          @media #{$headerBreakpoint} {
            position: relative;
          }
          &::before,
          &::after {
            @media #{$headerBreakpoint} {
              position: absolute;
              top: 4.5rem;
              right: 1.5rem;
              content: "";
              width: 2rem;
              height: .1rem;
              background: #fff;
            }
          }
          &::after {
            transform: rotate(90deg);
            transition: opacity $kitekiTransition, transform $kitekiTransition;
          }
          &.is-active {
            &::after {
              transform: rotate(0);
              opacity: 0;
            }
          }
        }
      }
      &_sub {
        $this: &;
        &:first-of-type {
          margin-right: 6rem;
          @media #{$headerBreakpoint} {
            margin: 0 0 2rem 0;
          }
        }
        &_wrapper {
          position: absolute;
          display: flex;
          justify-content: center;
          top: 100%;
          left: 0;
          right: 0;
          padding: 1.5rem 0;
          background: rgba(#000, .7);
          margin: 0 auto;
          opacity: 0;
          visibility: hidden;
          transition: opacity $kitekiTransition, visibility $kitekiTransition;
          @media #{$headerBreakpoint} {
            position: static;
            display: none;
            padding: 0 1.5rem 3rem;
            background: none;
            opacity: 1;
            visibility: visible;
          }
          &.is-active {
            opacity: 1;
            visibility: visible;
          }
        }
        &_link {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 1.8rem;
          font-weight: 400;
          transition: color $kitekiTransition;
          @media #{$headerBreakpoint} {
            font-size: 1.4rem;
          }
          &:hover {
            color: $keyColor;
            #{$this}_img {
              opacity: .7;
              @media #{$headerBreakpoint} {
                opacity: 1;
              }
            }
          }
        }
        &_img {
          width: 9rem;
          margin-right: 2rem;
          transition: opacity $kitekiTransition;
          @media #{$headerBreakpoint} {
            width: 5rem;
            margin-right: 1rem;
          }
        }
      }
    }
    &_sns {
      &_list {
        &_wrapper {
          display: flex;
          align-items: center;
          margin-left: 2rem;
          @media #{$headerBreakpoint} {
            margin-left: 0;
            margin-bottom: 3rem;
          }
        }
        &_link {
          display: block;
          svg {
            g {
              transition: fill $kitekiTransition;
            }
          }
          &:hover {
            svg {
              g {
                fill: $keyColor;
              }
            }
          }
          &.is-insta {
            width: 2rem;
            height: 2rem;
          }
          &.is-fb {
            width: 1.1rem;
            height: 2rem;
            margin-left: 2rem;
          }
        }
      }
    }
    &_reservation {
      &_btn {
        display: block;
        width: 17rem;
        height: 6rem;
        margin-left: 4rem;
        border: 1px solid $keyColor;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 5.8rem;
        color: $keyColor;
        transition: color $kitekiTransition, background $kitekiTransition;
        @media #{$headerBreakpoint} {
          width: 100%;
          margin: 0 auto;
        }
        &:hover {
          color: #000;
          background: $keyColor;
        }
      }
    }
  }
}
